import { Route, Routes } from "react-router-dom";
import GuestRoutes from "./GuestRoutes";
import AuthRoutes from "./AuthRoutes";

const RoutesContainer = () => {

  return (
    <Routes>
      <Route path={"/admin/*"} element={<AuthRoutes />} />
      <Route path={"/*"} element={<GuestRoutes />} />
    </Routes>
  )
}

export default RoutesContainer;