import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useEffect } from "react";

import { useQuery } from "react-query";
import { get } from "utilities/requests";
import Board from "./Board";

const ConsultoriaTable = ({ show_pending = false }) => {
  const { data, refetch, isLoading } = useQuery(
    "consultoria",
    async () =>
      await get(`contact-us/list?${show_pending && "get_pending=true"}`)
  );

  useEffect(() => {
    refetch();
  }, [show_pending]);

  return (
    <TableContainer
      component={Paper}
      style={{
        width: "100%",
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Nome</TableCell>
            <TableCell>E-mail</TableCell>
            <TableCell>Telefone</TableCell>
            <TableCell>Data</TableCell>
            <TableCell>Ações</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            // check if is loading and hide table
            isLoading ? (
              <TableRow>
                <TableCell colSpan={5}>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: "1.2rem",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CircularProgress
                      sx={{
                        width: "1.6rem!important",
                        height: "1.6rem!important",
                        marginRight: "0.8rem",
                      }}
                    />{" "}
                    Carregando...
                  </Typography>
                </TableCell>
              </TableRow>
            ) : (
              data?.news?.map((item, index) => (
                <Board
                  key={index}
                  item={item}
                  refetch={refetch}
                  show_pending={show_pending}
                />
              ))
            )
          }
        </TableBody>
      </Table>
      {data?.news?.length === 0 && !isLoading && (
        <Typography
          align={"center"}
          sx={{
            padding: 2,
          }}
        >
          Sem registros
        </Typography>
      )}
    </TableContainer>
  );
};

export default ConsultoriaTable;
