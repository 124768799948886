import { TableCell, TableRow, Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { maskDate } from "utilities/helpers";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { post } from "utilities/requests";
import { toast } from "react-toastify";
import swal from "sweetalert";

const Board = ({ item, show_pending, refetch }) => {

  const handleChangePending = async (status, id) => {
    const form = new FormData();
    form.append("id", id);
    form.append("status", status);

    const response = await post(`contact-us/change-pending`, form);

    if (response.status) {
      refetch();
      toast.success("Status alterado com sucesso!");
    } else {
      toast.error("Ocorreu um erro ao alterar o status!");
    }
  };

  const handleDelete = async (id) => {
    const form = new FormData();
    form.append("id", id);

    const response = await post(`contact-us/delete`, form);

    if (response.status) {
      refetch();
      toast.success("Registro deletado com sucesso!");
    } else {
      toast.error("Ocorreu um erro ao deletar o registro!");
    }
  };

  const handleShowMessage = async () => {
      swal({
        title: "Mensagem",
        text: item.message,
        icon: "info",
        button: "Fechar",
      });
  }


  return (
    <TableRow>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell>{item.phone}</TableCell>
      <TableCell>{maskDate(item.created_at)}</TableCell>
      {show_pending ? (
        <TableCell>
          <Tooltip title="Aprovar" arrow>
            <CheckIcon
              sx={{
                padding: "0.2rem",
                backgroundColor: "#4caf50",
                color: "#fff",
                fontSize: "1.8rem",
                borderRadius: "0.5rem",
                marginRight: "0.4rem",
                cursor: "pointer",
              }}
              onClick={() => handleChangePending("approved", item.id)}
            />
          </Tooltip>
          <Tooltip title="Negar" arrow>
            <CloseIcon
              sx={{
                padding: "0.2rem",
                backgroundColor: "#f44336",
                color: "#fff",
                fontSize: "1.8rem",
                borderRadius: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => handleChangePending("denied", item.id)}
            />
          </Tooltip>
        </TableCell>
      ) : (
        <TableCell>
          <Tooltip title="Deletar" arrow>
            <DeleteForeverIcon
              sx={{
                padding: "0.2rem",
                backgroundColor: "#f44336",
                color: "#fff",
                fontSize: "1.8rem",
                borderRadius: "0.5rem",
                marginRight: "0.4rem",
                cursor: "pointer",
              }}
              onClick={() => handleDelete(item.id)}
            />
          </Tooltip>
          <Tooltip title="Ver mensagem" arrow>
            <VisibilityIcon
              sx={{
                padding: "0.2rem",
                backgroundColor: "#1976d2",
                color: "#fff",
                fontSize: "1.8rem",
                borderRadius: "0.5rem",
                cursor: "pointer",
              }}
              onClick={() => handleShowMessage()}
            />
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  );
};

export default Board;
