import Landing from "./Landing";
import MVPSection from "./MVPSection";
// import Clientes from "./Clientes";
import FAQPage from "../FAQ";
import Contato from "../Contato";
import AboutUs from "./AboutUs";
import Prevencao from "./Prevencao";
import TimelineContainer from "./TimelineContainer";
import AltaTendencia from "./AltaTendencia";

const HomePage = ({ on_top }) => {
  return (
    <>
      <Landing {...{ on_top }} />
      <AboutUs />
      <MVPSection />
      <Prevencao />
      <TimelineContainer />
      <AltaTendencia />
      <FAQPage />
      <Contato invert_bg_theme={true} />
    </>
  );
};

export default HomePage;
