import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import { Typography } from "@mui/material";

const TimelineCard = ({ item, index }) => {
  const { title, list, list_with_title } = item;

  return (
    <TimelineItem
      sx={{
        maxWidth: "80rem",
      }}
    >
      <TimelineOppositeContent
        sx={{
          m: "auto 0",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
          },
        }}
        align="right"
      >
        <Typography
          sx={{
            color: "rgba(var(--accent-color1), 1)",
            fontWeight: 700,
            fontSize: "1.6rem",
          }}
        >
          {title}
        </Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineConnector />
        <TimelineDot
          sx={{
            bgcolor: "rgba(var(--accent-color1), 1)",
            color: "var(--background-color0)",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            padding: "1rem",
            fontSize: "1.4rem",
            fontWeight: 700,
            "&.MuiTimelineDot-filled": {
              width: "1.6rem",
              height: "1.6rem",
            },
          }}
        >
          {index + 1}
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 2 }}>
        {list_with_title ? (
          list.map((item, index) => (
            <div
              key={index}
              style={{
                textAlign: "left",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span
                style={{
                  color: "rgba(var(--accent-color0))",
                  margin: "0.8rem 0 0.6rem 0",
                  fontWeight: 600,
                  fontSize: "1.2rem",
                }}
              >
                {item.title}
              </span>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                {item.list.map((list_item, index) => (
                  <span
                    key={index}
                    style={{
                      display: "flex",
                      gap: "0.4rem",
                    }}
                  >
                    <span
                      style={{
                        color: "rgba(var(--accent-color0))",
                        fontWeight: 700,
                      }}
                    >
                      {index + 1}.
                    </span>
                    <span
                      style={{
                        fontSize: "1rem",
                      }}
                      dangerouslySetInnerHTML={{ __html: list_item }}
                    />
                  </span>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.2rem",
              width: "100%",
              textAlign: "left",
            }}
          >
            {list.map((item, index) => (
              <span
                key={index}
                style={{
                  display: "flex",
                  gap: "0.4rem",
                }}
              >
                <span
                  style={{
                    color: "rgba(var(--accent-color0))",
                    fontWeight: 700,
                  }}
                >
                  {index + 1}.
                </span>
                <span
                  style={{
                    fontSize: "1rem",
                  }}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              </span>
            ))}
          </div>
        )}
      </TimelineContent>
    </TimelineItem>
  );
};

export default TimelineCard;
