import main_white from "assets/static/logo/logo.png";
import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaMailBulk } from "react-icons/fa";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import HorizontalItems from "./HorizontalItems";
import login_button_style from "components/SpecificStyles/login_button.module.css";
import VerticalItems from "./VerticalItems";

const Navbar = ({ on_top, setOnTop, setForceOnTop, dark_mode }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const nav_links = [
    {
      name: "O que é",
      link: "/",
      element_id: "#o-que-e",
    },
    {
      name: "Benefícios",
      link: "/",
      element_id: "#beneficios",
    },
    {
      name: "Prevenções",
      link: "/",
      element_id: "#prevencoes",
    },
    {
      name: "Processo",
      link: "/",
      element_id: "#processo",
    },
    // {
    //   name: "Clientes",
    //   link: "/",
    //   element_id: "#clientes",
    // },
    {
      name: "FAQ",
      link: "/",
      element_id: "#faq",
    },
  ];

  const scrollTo = (element_id) => {
    if (location.pathname === "/") {
      const element = document.querySelector(element_id);
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      navigate("/", { replace: true });
      // after navigate to home, scroll to element
      setTimeout(() => {
        const element = document.querySelector(element_id);
        element.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    if (location.pathname !== "/") {
      setOnTop(false);
      setForceOnTop(true);
    } else {
      setOnTop(true);
      setForceOnTop(false);
    }
  }, [location]);

  // responsive menu
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <nav
      // NavbarContainer = main styles
      // Transition = main transition
      // NavbarOnTop = styles when navbar is on top
      className={
        styles.NavbarContainer +
        " " +
        "Transition-1" +
        (on_top ? " " + styles.NavbarOnTop : "")
      }
    >
      {/* responsive menu items */}
      <VerticalItems
        {...{
          openMenu,
          setOpenMenu,
          nav_links,
          scrollTo,
          styles,
          logo: main_white,
          navigate,
        }}
      />
      <div
        className={"Transition-1"}
        style={{
          maxWidth: on_top ? "100%" : "1536px",
          width: "100%",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: on_top
            ? "0.6rem 2rem"
            : "0.6rem 0.8rem 0.6rem 2rem",
          marginBottom: "0",
          borderRadius: "5rem",
          zIndex: 100,
          backgroundColor: on_top ? "transparent" : "var(--navbar-color)",
          // backdropFilter: on_top ? "none" : "blur(2rem)",
          boxShadow: on_top
            ? "none"
            : "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
        }}
      >
        <a href="/">
          <img
            className={"Transition-1"}
            src={main_white}
            alt="OnTest Logotipo"
            style={{
              width: on_top ? "9rem" : "7rem",
            }}
          />
        </a>

        {/* main items */}
        <HorizontalItems
          {...{ on_top, nav_links, scrollTo, styles, navigate }}
        />

        <div
          className="Transition-1"
          style={{
            width: on_top ? "9rem" : "7rem",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Link to={"/contato"} className="remove-underline">
            <button
              className={`Transition-1 ${login_button_style.loginButton}`}
              style={{
                position: "relative",
                letterSpacing: "0.1rem",
                perspective: "1500px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className={`${login_button_style.cardFlipEffect} Transition-1`}
              />
              <FaMailBulk
                style={{
                  marginRight: "0.6rem",
                  display: "flex",
                  zIndex: 2,
                  color: "var(--background-color0)",
                }}
                className="Transition-1"
              />
              <span
                style={{ zIndex: 2, position: "relative", lineHeight: "1.2" }}
              >
                Agendar Consultoria
              </span>
            </button>
          </Link>

          <MenuRoundedIcon
            onClick={() => setOpenMenu(true)}
            sx={{
              display: { xs: "block", md: "flex", lg: "none" },
              fontSize: "2.5rem",
              marginLeft: "0.4rem",
            }}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
