import { Navigate, Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import logo from "assets/static/logo/logo.png";
import { Typography } from "@mui/material";
import { useEffect, useState } from "react";
import Aside from "pages/auth/AsideAdmin";
import ConsultoriaTable from "pages/auth/Consultoria";

const AuthRoutes = () => {
  let navigate = useNavigate();
  const token = localStorage.getItem("token");

  const [readyToRender, setReadyToRender] = useState(false);
  
  const checkLogin = () => {
    if (token === null) {
      return navigate("/login");
    } else {
      setReadyToRender(true);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  return readyToRender && (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Aside />
      <Routes>
        <Route path="/consultoria" element={<ConsultoriaTable />} />
        <Route path="/consultoria/pendente" element={<ConsultoriaTable show_pending={true} />} />
        {/* index */}
        <Route path="*" element={<div style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%",
          }}>
            <img src={logo} alt="EnterScience Logotipo" 
              style={{width: "20rem", objectFit: "contain"}}
            />
            <Typography
              variant="h4"
              component="h4"
              style={{ color: "var(--text-reverse-1)", marginTop: "2rem", fontWeight: 'bold' }}
            >
              Seja bem-vindo(a) ao Dashboard
            </Typography>
          </div>} />

        {/* generic 404 */}
        <Route path="*" element={<Navigate to="/dashboard" />} />

          
      </Routes>
    </div>
  )
}

export default AuthRoutes;