import { Box, Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import login_button_style from "components/SpecificStyles/login_button.module.css";
import { FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";

import img1 from "assets/static/trend_imgs/bg1.jpg";
import img2 from "assets/static/trend_imgs/bg2.jpg";
import img3 from "assets/static/trend_imgs/bg3.jpg";
import img4 from "assets/static/trend_imgs/bg4.jpg";

const AltaTendencia = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="var(--background-color1)"
      id="sobre-nos"
    >
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "6rem 1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "30px auto 0",
            },
          }}
        >
          Alta Tendência
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: "4rem",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              width: {
                xs: "100%",
                sm: "80%",
                md: "50%",
              },
              textAlign: {
                xs: "center",
                sm: "center",
                md: "left",
              },
              alignItems: {
                xs: "center",
                sm: "center",
                md: "flex-start",
              },
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.3rem",
                color: "var(--text-color0)",
                marginBottom: "2rem",
              }}
            >
              O meio digital está crescendo e as provas online geram{" "}
              <b>mais benefícios a baixos custos</b> às aplicadoras e às
              instituições de ensino.
            </Typography>
            <Typography
              sx={{
                fontSize: "1.1rem",
                color: "var(--text-color1)",
              }}
            >
              Dados dos casos de sucesso com o uso do OnTest mostram que a
              adesão por provas online pelos candidatos{" "}
              <b>cresceu mais de 78%</b>.
            </Typography>
            <Typography
              sx={{
                fontSize: "1.1rem",
                color: "var(--text-color1)",
              }}
            >
              Possibilite que mais candidatos tenham acesso à sua instituição de
              ensino!
            </Typography>

            <div
              className="Transition-1"
              style={{
                width: "16rem",
                display: "flex",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Link to={"/contato"} className="remove-underline">
                <button
                  className={`Transition-1 ${login_button_style.loginButton}`}
                  style={{
                    position: "relative",
                    letterSpacing: "0.1rem",
                    perspective: "1500px",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div
                    className={`${login_button_style.cardFlipEffect} Transition-1`}
                  />
                  <FaMailBulk
                    style={{
                      marginRight: "0.6rem",
                      display: "flex",
                      zIndex: 2,
                      color: "var(--background-color0)",
                    }}
                    className="Transition-1"
                  />
                  <span
                    style={{
                      zIndex: 2,
                      position: "relative",
                      lineHeight: "1.2",
                    }}
                  >
                    Solicitar demonstração
                  </span>
                </button>
              </Link>
            </div>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: {
                xs: "90%",
                sm: "80%",
                md: "50%",
              },
              position: "relative",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: "1rem",
                left: "1rem",
                width: "100%",
                height: "100%",
                borderRadius: "1.8rem 2.6rem 2.6rem 2.6rem",
                border: "2px solid rgba(var(--accent-color0), 1)",
                zIndex: 2,
              }}
            />

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginBottom: "2%",
                zIndex: 1,
              }}
            >
              <img
                src={img1}
                alt="Tendência: uso do computador em casa"
                style={{
                  objectFit: "cover",
                  width: "49%",
                  borderRadius: "2rem 0 0 0",
                }}
              />
              <img
                src={img2}
                alt="Tendência: uso do computador em casa"
                style={{
                  objectFit: "cover",
                  width: "49%",
                  borderRadius: "0 2rem 0 0",
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                zIndex: 1,
              }}
            >
              <img
                src={img3}
                alt="Tendência: uso do computador em casa"
                style={{
                  objectFit: "cover",
                  width: "49%",
                  borderRadius: "0 0 0 2rem",
                }}
              />
              <img
                src={img4}
                alt="Tendência: uso do computador em casa"
                style={{
                  objectFit: "cover",
                  width: "49%",
                  borderRadius: "0 0 2rem 0",
                }}
              />
            </div>
          </Box>
        </Box>
      </Container>
    </CustomSection>
  );
};

export default AltaTendencia;
