import { Typography } from "@mui/material";
import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import swal from "sweetalert";
import form_styles from "components/SpecificStyles/form.module.css";
import { useState } from "react";
import { post } from "utilities/requests";
import CustomInput from "components/CustomInput";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

const Contato = ({ invert_bg_theme = false }) => {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const phoneMaskBrazil = (e) => {
    if (e.target.value.length > 15) return;

    let element = e.target;
    let isAllowed = /\d|Backspace|Tab/;
    if (!isAllowed.test(e.nativeEvent.data)) e.preventDefault();

    let inputValue = element.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
    inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");

    setPhone(inputValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "") {
      swal("Erro!", "O campo nome é obrigatório!", "error");
      return;
    } else if (email === "") {
      swal("Erro!", "O campo e-mail é obrigatório!", "error");
      return;
    } else if (phone === "") {
      swal("Erro!", "O campo telefone é obrigatório!", "error");
      return;
    } else if (phone.length < 14) {
      swal("Erro!", "O campo telefone está inválido!", "error");
      return;
    } else if (message === "") {
      swal("Erro!", "O campo mensagem está vazio... Por favor insira o motivo do contato.", "error");
      return;
    }

    if (loading) return;

    setLoading(true);

    let data = new FormData();

    data.append("name", name);
    data.append("email", email);
    data.append("phone", phone);
    data.append("message", message);

    let response = await post("contact-us/add", data);

    if (response.status) {
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");

      swal("Sucesso!", "Sua mensagem foi enviada com sucesso!", "success");
    } else {
      swal("Erro!", "Ocorreu um erro ao enviar sua mensagem, por favor entre em contato com o suporte.", "error");
    }

    setLoading(false);
  };

  return (
    <CustomSection
      style={{
        overflow: "hidden",
        paddingTop: "5rem",
      }}
      bg_color={invert_bg_theme && "var(--background-color1)"}
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Agendar consultoria
        </Typography>

        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "30rem",
          }}
        >
          Preencha o formulário abaixo para agendar uma consultoria com um de nossos especialistas.
        </Typography>

        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
            width: "100%",
            maxWidth: "36rem",
          }}
          // className={
          //   invert_bg_theme
          //     ? form_styles.customFormStyleInverted
          //     : form_styles.customFormStyle
          // }
          className={`${form_styles.customFormStyle} ${
            invert_bg_theme && form_styles.invertedBgTheme
          }`}
          onSubmit={handleSubmit}
        >
          <CustomInput
            placeholder="Nome completo"
            icon={PersonIcon}
            value={name}
            onChange={setName}
            helperText="Insira seu nome civil ou social."
            required
          />
          <CustomInput
            type="email"
            placeholder="E-mail"
            icon={EmailIcon}
            value={email}
            onChange={setEmail}
            helperText="Insira seu e-mail para que possamos entrar em contato."
            required
          />
          <CustomInput
            placeholder="Telefone"
            icon={LocalPhoneIcon}
            value={phone}
            onChangeTarget={phoneMaskBrazil}
            helperText="Insira seu telefone para que possamos entrar em contato."
            required
          />
          <textarea
            placeholder="Mensagem"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <input type="submit" />
        </form>

        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "40rem",
            marginTop: "2rem",
          }}
        >
          Não se preocupe! Seus dados não serão compartilhados com terceiros.
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontSize: "0.8rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "40rem",
            marginTop: "2rem",
            opacity: 0.6,
          }}
        >
          Ao responder o presente formulário, declaro, COMO TITULAR dos dados aqui fornecidos, que autorizo a empresa EnterScience, aqui denominada CONTROLADORA, disponha dos meus dados pessoais e dados pessoais sensíveis, de acordo com os artigos 7° e 11 da Lei n° 13.709/2018. Os dados serão utilizados para fim de contato através do WhatsApp, e-mail e telefone. Como TITULAR você poderá revogar o consentimento a qualquer momento. Declaro que estou ciente que à Controladora, é permitido manter e utilizar meus dados pessoais durante o período em que houver eventual negociação.
        </Typography>
      </Container>
    </CustomSection>
  );
};

export default Contato;
