import { Box, Container, Typography } from "@mui/material";
import MVPItem from "./MVPItem";
import login_button_style from "components/SpecificStyles/login_button.module.css";
import { FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";

import mvp1 from "assets/static/random/mvp1.png";
import mvp2 from "assets/static/random/mvp2.png";
import mvp3 from "assets/static/random/mvp3.png";
import mvp4 from "assets/static/random/mvp4.png";
import mvp5 from "assets/static/random/mvp5.png";
import mvp6 from "assets/static/random/mvp6.png";

const { default: CustomSection } = require("components/CustomSection");

const items = [
  {
    title: "Criação de provas",
    list: [
      "Questões objetivas, dissertativas e redações",
      "Diagramação automática",
      "Categorização de questões e provas",
      "Formas fáceis e rápidas de inserir questões próprias",
    ],
    text: null,
    icon: mvp1,
  },
  {
    title: "White Label",
    list: null,
    text: "Você tem total liberdade em solicitar que a sua prova venha com o <b>seu logotipo</b>.",
    icon: mvp2,
  },
  {
    title: "Aplicação",
    list: [
      "Ensalamento",
      "Recursos anti-cola",
      "Avaliações pré-agendadas",
      "Escolha da quantidade de candidatos por salas",
    ],
    text: null,
    icon: mvp3,
  },
  {
    title: "Correção",
    list: null,
    text: "O Ontest oferece um ambiente de correção avançado para questões dissertativas e redações.",
    icon: mvp4,
  },
  {
    title: "Integração via API",
    list: null,
    text: "Precisa de integrar o OnTest no seu sistema? Faça a integração com a API do OnTest.",
    icon: mvp5,
  },
  {
    title: "Benefícios extras",
    list: [
      "Totalmente auditável",
      "Equipe de suporte presente no processo",
      "Possibilidade de utilização de vários idiomas",
      "Rápido acesso à prova, gabarito e filmagens",
    ],
    text: null,
    icon: mvp6,
  },
];

const MVPSection = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="var(--background-color0)"
      id="beneficios"
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "6rem 1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "30px auto 0",
            },
          }}
        >
          Benefícios
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          {items.map((item, index) => (
            <MVPItem
              key={index}
              title={item.title}
              text={item.text}
              icon={item.icon}
              list={item.list}
            />
          ))}
          {/* <MVPItem
            title="Segurança e Privacidade"
            description="Entendemos a importância da segurança e privacidade durante as videochamadas. Por isso, implementamos medidas rigorosas para proteger suas informações e garantir que suas chamadas sejam privadas. Utilizamos criptografia de ponta a ponta e adotamos práticas de segurança robustas para manter seus dados seguros."
            icon={privacy_icon}
            align_left={false}
          /> */}
        </Box>
        <div
          className="Transition-1"
          style={{
            width: "16rem",
            display: "flex",
            position: "relative",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <Link to={"/contato"} className="remove-underline">
            <button
              className={`Transition-1 ${login_button_style.loginButton}`}
              style={{
                position: "relative",
                letterSpacing: "0.1rem",
                perspective: "1500px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className={`${login_button_style.cardFlipEffect} Transition-1`}
              />
              <FaMailBulk
                style={{
                  marginRight: "0.6rem",
                  display: "flex",
                  zIndex: 2,
                  color: "var(--background-color0)",
                }}
                className="Transition-1"
              />
              <span
                style={{ zIndex: 2, position: "relative", lineHeight: "1.2" }}
              >
                Solicitar demonstração
              </span>
            </button>
          </Link>
        </div>
      </Container>
    </CustomSection>
  );
};

export default MVPSection;
