import { Button, Typography } from "@mui/material";
import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import swal from "sweetalert";
import form_styles from "components/SpecificStyles/form.module.css";
import { useState } from "react";
import { post } from "utilities/requests";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import CustomInput from "components/CustomInput";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Contato = () => {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [linkedin_url, setLinkedinUrl] = useState("");
  const [curriculum, setCurriculum] = useState(null);

  const phoneMaskBrazil = (e) => {
    if (e.target.value.length > 15) return;

    let element = e.target;
    let isAllowed = /\d|Backspace|Tab/;
    if (!isAllowed.test(e.nativeEvent.data)) e.preventDefault();

    let inputValue = element.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
    inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");

    setPhone(inputValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "") {
      swal("Erro!", "O campo nome é obrigatório!", "error");
      return;
    } else if (email === "") {
      swal("Erro!", "O campo e-mail é obrigatório!", "error");
      return;
    } else if (phone === "") {
      swal("Erro!", "O campo telefone é obrigatório!", "error");
      return;
    } else if (phone.length < 14) {
      swal("Erro!", "O campo telefone está inválido!", "error");
      return;
    } else if (linkedin_url === "") {
      swal("Erro!", "O campo linkedin é obrigatório!", "error");
      return;
    } else if (curriculum === null) {
      swal("Erro!", "O campo currículo é obrigatório!", "error");
      return;
    }

    if (loading) return;

    setLoading(true);

    let data = new FormData();

    data.append("name", name);
    data.append("email", email);
    data.append("phone", phone);
    data.append("linkedin_url", linkedin_url);
    data.append("curriculum", curriculum);

    let response = await post("work-with-us/add", data);

    if (response.status) {
      setName("");
      setEmail("");
      setPhone("");
      setLinkedinUrl("");
      setCurriculum(null);

      swal("Sucesso!", "Sua mensagem foi enviada com sucesso!", "success");
    } else {
      swal("Erro!", "Ocorreu um erro ao enviar sua mensagem, por favor entre em contato com o suporte.", "error");
    }

    setLoading(false);
  };

  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
          }}
        >
          Faça parte da nossa equipe
        </Typography>

        {/* <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "30rem",
          }}
        >
          
        </Typography> */}

        <form
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            marginTop: "2rem",
            width: "100%",
            maxWidth: "36rem",
          }}
          className={form_styles.customFormStyle}
          onSubmit={handleSubmit}
        >
          <CustomInput
            placeholder="Nome completo"
            icon={PersonIcon}
            value={name}
            onChange={setName}
            helperText="Insira seu nome civil ou social."
            required
          />
          <CustomInput
            type="email"
            placeholder="E-mail"
            icon={EmailIcon}
            value={email}
            onChange={setEmail}
            helperText="Insira seu e-mail para que possamos entrar em contato."
            required
          />
          <CustomInput
            placeholder="Telefone"
            icon={LocalPhoneIcon}
            value={phone}
            onChangeTarget={phoneMaskBrazil}
            helperText="Insira seu telefone para que possamos entrar em contato."
            required
          />
          <CustomInput
            placeholder="Link do seu perfil no LinkedIn"
            icon={LinkedInIcon}
            value={linkedin_url}
            onChange={setLinkedinUrl}
            helperText="Insira seu perfil no LinkedIn para que possamos conhecer melhor seu perfil."
            required
          />

          <label
            style={{
              cursor: "pointer",
            }}
          >
            {curriculum ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <TextSnippetIcon />
                <Typography variant="body1">{curriculum.name}</Typography>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                <TextSnippetIcon />
                <Typography variant="body1">
                  Clique para anexar o currículo
                </Typography>
              </div>
            )}
            <input
              hidden
              type="file"
              name="upload"
              accept="application/pdf,application/vnd.ms-excel"
              style={{
                border: "none",
                backgroundColor: "transparent",
              }}
              onChange={(e) => {
                setCurriculum(e.target.files[0]);
              }}
            />
          </label>

          <input type="submit" />
        </form>

        <Typography
          variant="p"
          sx={{
            fontSize: "1.1rem",
            textAlign: "center",
            margin: "0 auto",
            fontWeight: "normal",
            maxWidth: "40",
            marginTop: "2rem",
          }}
        >
          Não se preocupe! Seus dados não serão compartilhados com terceiros.
        </Typography>
      </Container>
    </CustomSection>
  );
};

export default Contato;
