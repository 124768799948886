import { Box } from "@mui/material";

const MVPItem = ({ title, text, list, icon }) => {
  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        gap: "2rem",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        // width: "38rem",
        flexBasis: "38rem",
        flexGrow: 1,

        background: "linear-gradient(180deg, var(--background-color1) 0%, var(--background-color0) 100%)",
        padding: "2rem",
        borderRadius: "1rem",
        alignItems: {
          xs: "center",
          sm: "inherit",
        },
      }}
    >
      <Box
        component="img"
        src={icon}
        alt="MVP Icon"
        sx={{
          width: {
            xs: "7rem",
            sm: "9rem",
          },
          objectFit: "contain",
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.8rem",
          textAlign: {
            xs: "center",
            sm: "left",
          },
        }}
      >
        <span
          style={{
            fontWeight: 600,
            fontSize: "1.3rem",
          }}
        >
          {title}
        </span>
        <span
          style={{
            fontSize: "1rem",
            textAlign: "justify",
          }}
          dangerouslySetInnerHTML={{ __html: text }}
        />
        
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.2rem",
            width: "100%",
            textAlign: "left",
          }}
        >
        {
          list && list.map((item, index) => (
            <span
              key={index}
              style={{
                display: "flex",
                gap: "0.4rem",
              }}
            >
              <span
                style={{
                  color: "rgba(var(--accent-color0))",
                  fontWeight: 700,
                }}
              >
                {index+1}.
              </span>
              <span
                style={{
                  fontSize: "1rem",
                }}
                dangerouslySetInnerHTML={{ __html: item }}
              />
            </span>
          ))
        }
        </div>
      </Box>
    </Box>
  );
};

export default MVPItem;
