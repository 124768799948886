import { Box, Container, Typography } from "@mui/material";
import login_button_style from "components/SpecificStyles/login_button.module.css";
import { FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";

import icon1 from "assets/static/random/icon1.jpg";
import icon2 from "assets/static/random/icon2.jpg";
import icon3 from "assets/static/random/icon3.jpg";

import AboutUsItem from "./AboutUsItem";

const { default: CustomSection } = require("components/CustomSection");

const items = [
  {
    title: "Estrutura robusta",
    text: "A estrutura tecnológica do OnTest proporciona grandes volumes de tráfego de dados sem prejudicar o desempenho para fiscais, aplicadores e candidatos.",
    icon: icon1,
  },
  {
    title: "Suporte na tomada de decisão",
    text: "Com a Inteligência Artificial do OnTest, a avaliação de candidatos e a tomada de decisão frente às situações delicadas se torna fácil.",
    icon: icon2,
  },
  {
    title: "Praticidade",
    text: "Economize o seu tempo na gestão da criação, logística e aplicação das provas. Além disso, tenha relatórios detalhados de ações do candidato.",
    icon: icon3,
  },
];

const AboutUs = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="var(--background-color1)"
      id="o-que-e"
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "6rem 1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "30px auto 0",
            },
          }}
        >
          O que é o OnTest?
        </Typography>

        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontWeight: 400,
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: "var(--text-color1)",
            maxWidth: "60rem",
            margin: "0 auto",
          }}
        >
          O OnTest é um software que utiliza Inteligência Artificial para
          possibilitar a escalabilidade nos processos seletivos de instituições
          de ensino por todo o mundo, usufruindo de segurança e monitoramento
          avançado.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "2rem",
          }}
        >
          {items.map((item, index) => (
            <AboutUsItem
              key={index}
              title={item.title}
              text={item.text}
              icon={item.icon}
            />
          ))}
        </Box>

        <div
          className="Transition-1"
          style={{
            width: "18rem",
            display: "flex",
            position: "relative",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <Link to={"/contato"} className="remove-underline">
            <button
              className={`Transition-1 ${login_button_style.loginButton}`}
              style={{
                position: "relative",
                letterSpacing: "0.1rem",
                perspective: "1500px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className={`${login_button_style.cardFlipEffect} Transition-1`}
              />
              <FaMailBulk
                style={{
                  marginRight: "0.6rem",
                  display: "flex",
                  zIndex: 2,
                  color: "var(--background-color0)",
                }}
                className="Transition-1"
              />
              <span
                style={{ zIndex: 2, position: "relative", lineHeight: "1.2" }}
              >
                Conversar com um Consultor
              </span>
            </button>
          </Link>
        </div>
      </Container>
    </CustomSection>
  );
};

export default AboutUs;
