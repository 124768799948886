import { Box, Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";

import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ThumbsUpDownIcon from '@mui/icons-material/ThumbsUpDown';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import Timeline from "@mui/lab/Timeline";
import TimelineCard from "./TimelineCard";

const items_list = [
  {
    title: "Inscrição",
    list: [
      "Gestão do processo seletivo;",
      "Gestão dos editais;",
      "Criação de formulário socioeconômico;",
      "Acompanhamento das inscrições;",
      "Relatórios de respostas;",
      "Gestão financeira.",
    ],
    list_with_title: false,
  },
  {
    title: "Avaliação",
    list: [
      {
        title: "Durante a prova:",
        list: [
          "Disponibilização de ambiente para que um representante da Empresa Contratante possa acompanhar a aplicação, inclusive com visualização da imagem dos candidatos/salas virtuais;",
          "Suporte Técnico com equipe de Tecnologia da Informação de plantão durante todo o tempo de realização da prova.",
        ],
      },
      {
        title: "Após a realização da prova:",
        list: [
          "Fornecimento de planilha com todas as respostas assinaladas pelos candidatos;",
          "Fornecimento de todos os vídeos, áudios, screenshots e registros de diálogos entre fiscais e candidatos;",
          "Fornecimento de relatório de desempenho dos servidores durante o período de aplicação.",
        ],
      },
    ],
    list_with_title: true,
  },
  {
    title: "Correção",
    list: [
      "Correção das questões/redações por sala;",
      "Possibilidade de trabalhar em diversas salas, de acordo com a distribuição necessária em cada processo seletivo;",
      "Simples navegação entre as salas;",
      "Ferramentas de marcação no texto;",
      "Adaptação dos critérios de correção, de acordo com às necessidades.",
    ],
    title_with_list: false,
  },
];

const TimelineContainer = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="var(--background-color0)"
      id="processo"
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "6rem 1rem",
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: "center",
            fontWeight: 600,
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "30px auto 0",
            },
          }}
        >
          Conduzimos o processo integralmente
        </Typography>

        <Timeline
          sx={{
            padding: "0",
            display: {
              xs: "flex",
              sm: "flex",
              md: "none",
            },
            [`& .${timelineOppositeContentClasses.root}`]: {
              flex: 0.2,
            },
          }}
        >
          {items_list.map((item, index) => (
            <TimelineCard key={index} {...{ item, index }} />
          ))}
        </Timeline>

        <Timeline position="alternate"
          sx={{
            margin: "0 auto",
            padding: "0",
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            }
          }}
        >
          {items_list.map((item, index) => (
            <TimelineCard key={index} {...{ item, index }} />
          ))}
        </Timeline>
      </Container>
    </CustomSection>
  );
};

export default TimelineContainer;
