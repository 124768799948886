import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import { Typography } from "@mui/material";
import SwiperCarousel from "./SwiperContainer";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const Landing = ({on_top}) => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
      bg_color="var(--background-color0)"
      id="home"
      remove_border={true}
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",

          justifyContent: "end",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography
          sx={{
            color: "rgba(var(--accent-color1))",
            fontWeight: 800,
            fontSize: {
              xs: "3.5vh",
              sm: "4.5vh",
              md: "5vh",
              lg: "6vh",
            },
            marginBottom: "2vh",
            textAlign: "center",
            zIndex: 2,
            maxWidth: "70rem",
          }}
        >
          A <u style={{color: 'rgba(var(--accent-color0))'}}>melhor solução</u> para aplicadoras e instituições de ensino
        </Typography>

        <div
          style={{
            maxWidth: "60rem",
            // color: "rgba(var(--accent-color1))",
            color: "var(--background-color1)",
            zIndex: 2,
            position: "absolute",
            bottom: "6vh",
            // backgroundColor: "rgba(255, 255, 255, 0.5)",
            backgroundColor: "rgba(var(--accent-color1), 0.6)",
            backdropFilter: "blur(10px)",
            borderRadius: "1rem",
            padding: "1rem 2rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.1rem",
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            Solução completa para criação, aplicação e correção de provas para processos concorrenciais ou não, para milhares de candidatos.
          </Typography>

          {/* <KeyboardDoubleArrowDownIcon 
            className="Transition-1"
            sx={{
              opacity: on_top ? 1 : 0,
              fontSize: "4.5rem",
              position: "absolute",
              bottom: on_top ? "-2.8rem" : "-4rem",
              left: "50%",
              transform: "translateX(-50%)",
              color: "rgba(var(--accent-color0))",
              cursor: "pointer",
            }}
            onClick={() => {
              const element = document.querySelector("#o-que-e");
            element.scrollIntoView({ behavior: "smooth" });
            }}
          /> */}
        </div>

        <SwiperCarousel />

      </Container>
    </CustomSection>
  );
};

export default Landing;
