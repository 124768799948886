import { Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import FAQItem from "./FAQItem";
import faq_bg from "assets/static/faq_bg.jpg";

const faq_list = [
  {
    question: "As salas comportam quantos participantes?",
    answer:
      "As salas virtuais comportam dezenas de candidatos. Porém, a partir de estudos operacionais, validou-se que uma quantidade média de 24 candidatos por sala é o ideal para facilitar a vigilância de fiscais humanos.",
  },
  {
    question: "Posso ter mais de 1 fiscal por sala?",
    answer: "Sim! É possível ter mais de 1(um) fiscal por cada sala.",
  },
  {
    question:
      "É possível escutar o áudio de um candidato em uma situação suspeita?",
    answer:
      "Sim! Na situação em que o fiscal avalia ser suspeita, então ele poderá habilitar o áudio do candidato para escutar e entender melhor a situação. Nenhum dos outros candidatos escutarão o áudio deste candidato.",
  },
  {
    question: "Os fiscais podem falar entre si através de um chat?",
    answer:
      "Sim! O OnTest fornece a ferramenta de chat para facilitar a comunicação entre os fiscais da sala.",
  },
  {
    question: "Quais tipos de questões o OnTest suporta?",
    answer:
      "O OnTest permite questões de múltipla escolha, dissertativas e redação.",
  },
  {
    question: "Qual é o prazo para efetuar o download dos relatórios?",
    answer:
      "O prazo padrão para se efetuar o download dos relatórios da prova/concurso é de 30 dias. Porém, isso pode ser revisado, a depender da necessidade do cliente.",
  },
  {
    question: "Consigo gerenciar todas as unidades da minha instituição?",
    answer:
      "Com certeza! Se você possui várias unidades da sua Instituição de Ensino, é possível gerenciá-las por meio do OnTest.",
  },
  {
    question: "Quero personalizasr o sistema OnTest, é possível?",
    answer:
      "Sim, é possível realizar personalização do sistema OnTest para que esteja com o um logotipo próprio. O OnTest é um sistema totalmente “whitelabel”, ou seja, a marca OnTest não aparecerá para os candidatos.",
  },
  {
    question: "Existe um time de suporte para apoiar no dia da prova/concurso?",
    answer:
      "Sim! Nosso time de suporte estará preparado para qualquer tipo de demanda que surgir no dia da prova/concurso.",
  },
  {
    question: "Quem irá inserir as questões à prova/concurso?",
    answer:
      "A inserção das questões deve ser feita pela própria instituição/aplicadora de provas. Dessa forma, a instituição/aplicadora poderá customizar a prova ao seu estilo particular.",
  },
];

const FAQPage = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        position: "relative",
        gap: "4rem",
        padding: "6rem 0",
        height: "max-content",
        minHeight: "max-content",
      }}
      bg_color="var(--background-color0)"
      id="faq"
      remove_border={true}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100%",
          overflow: "hidden",

          maskImage: "linear-gradient(to top, transparent 0%, black 50%)",
          WebkitMaskImage: "linear-gradient(to top, transparent 0%, black 50%)",
        }}
      >
        <img
          src={faq_bg}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            opacity: 0.8,
          }}
          alt="FAQ plano de fundo"
        />
      </div>

      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "max-content",
          gap: "2rem",
          zIndex: 1,
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "30px auto 0",
            },
          }}
        >
          Perguntas frequentes
        </Typography>

        {/* <FAQItem
          index={1}
          question="Posso compartilhar minha tela durante uma videochamada?"
          answer="Sim, você pode compartilhar sua tela durante uma videochamada. Durante a chamada, basta procurar o ícone de compartilhamento de tela na barra de ferramentas e selecionar a janela ou aplicativo que deseja compartilhar."
        /> */}
        {faq_list.map((item, index) => (
          <FAQItem
            key={index}
            index={index}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </Container>
    </CustomSection>
  );
};

export default FAQPage;
