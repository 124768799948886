import { Box, Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import login_button_style from "components/SpecificStyles/login_button.module.css";
import { FaMailBulk } from "react-icons/fa";
import { Link } from "react-router-dom";

import prevention from "assets/static/random/prevention.jpg";

const items_list = [
  "Captação de imagens estáticas de tela em altíssima periodicidade;",
  "Captação de vídeo em todo o tempo de prova;",
  "Relatório instantâneo de movimentos do candidato;",
  "Detecção de objetos proibidos durante o processo;",
  "Chat entre fiscal e candidato;",
  "Fiscalização humana e por meio de Inteligência Artificial;",
  "Registro/salvamento automático de todas as ações dos candidatos no sistema;",
  "Protocolo de transferência seguro (HTTPS), senhas criptografadas e ambiente totalmente em nuvem;",
  "Identificador de alternância de tela com registro e aviso instantâneo para o candidato;",
  "Acesso à tela em que o candidato se encontra.",
];

const Prevencao = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="var(--background-color1)"
      id="prevencoes"
    >
      <Container
        maxWidth="xl"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "4rem",
          padding: "6rem 1rem",
        }}
      >
        <Typography
          variant="h2"
          sx={{
            textAlign: "center",
            fontWeight: 600,
            "&:after": {
              content: "''",
              display: "block",
              marginTop: "30px",
              width: "8rem",
              borderTop: "2px solid rgba(var(--accent-color0), 1)",
              margin: "30px auto 0",
            },
          }}
        >
          Prevenções de Fraudes
        </Typography>

        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontWeight: 400,
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
            color: "var(--text-color1)",
            maxWidth: "60rem",
            margin: "0 auto",
          }}
        >
          O OnTest é um software que utiliza Inteligência Artificial para
          possibilitar a escalabilidade nos processos seletivos de instituições
          de ensino por todo o mundo, usufruindo de segurança e monitoramento
          avançado.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            gap: "4rem",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: {
                xs: "100%",
                md: "30rem",
              },
              position: "relative",
            }}
          >
            <img
              src={prevention}
              style={{
                width: "100%",
                objectFit: "cover",
                objectPosition: "left",
                display: "block",
                borderRadius: "2rem",
              }}
            />
            <div
              style={{
                position: "absolute",
                top: "-1rem",
                left: "-1rem",
                width: "100%",
                height: "100%",
                borderRadius: "2.6rem 2.6rem 1.8rem 2.6rem",
                border: "2px solid rgba(var(--accent-color0), 1)",
                zIndex: 2,
              }}
            />
          </Box>

          <div
            style={{
              maxWidth: "40rem",
              display: "flex",
              flexDirection: "column",
              gap: "0.4rem",
            }}
          >
            {items_list.map((item, index) => (
              <span
                key={index}
                style={{
                  display: "flex",
                  gap: "0.4rem",
                  fontSize: "1.1rem",
                }}
              >
                <span
                  style={{
                    fontSize: "1rem",
                    color: "rgba(var(--accent-color0))",
                    fontWeight: 700,
                  }}
                >
                  {index + 1}.
                </span>
                <span dangerouslySetInnerHTML={{ __html: item }} />
              </span>
            ))}
          </div>
        </Box>

        <div
          className="Transition-1"
          style={{
            width: "16rem",
            display: "flex",
            position: "relative",
            justifyContent: "center",
            margin: "0 auto",
          }}
        >
          <Link to={"/contato"} className="remove-underline">
            <button
              className={`Transition-1 ${login_button_style.loginButton}`}
              style={{
                position: "relative",
                letterSpacing: "0.1rem",
                perspective: "1500px",
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className={`${login_button_style.cardFlipEffect} Transition-1`}
              />
              <FaMailBulk
                style={{
                  marginRight: "0.6rem",
                  display: "flex",
                  zIndex: 2,
                  color: "var(--background-color0)",
                }}
                className="Transition-1"
              />
              <span
                style={{ zIndex: 2, position: "relative", lineHeight: "1.2" }}
              >
                Solicitar demonstração
              </span>
            </button>
          </Link>
        </div>
      </Container>
    </CustomSection>
  );
};

export default Prevencao;
